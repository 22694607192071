import React, { createContext, useEffect, useState } from 'react';
import commonApi from '../utiles/api';
import { useLocation, useParams } from 'react-router-dom';

export const NewsContext = createContext();

export function NewsContextProvider({ children }) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const stateParam = query.get('state');
    const rowState = stateParam ? JSON.parse(atob(stateParam)) : null;
    const { slug } = useParams();

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [newsData, setNewsData] = useState([]);
    const [newsDetail, setNewsDetail] = useState([]);


    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async () => {
        let data = { slug: slug };
        setIsLoading(true);
        try {
            const response = await commonApi.post('news/front/list', data);
            if (response.data.s === 1) {
                setNewsData(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('news/front/view', { id: id });
            if (response.data.s === 1) {
                setNewsDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (location.pathname.startsWith('/news/')) {
            onLoadApi();
        }
    }, [slug]);
    useEffect(() => {
        if (rowState) {
            onDetailApi(rowState?.id);
        }
    }, [location]);
    const contextValue = {
        newsDetail,
        setNewsDetail,
        newsData,
        setNewsData,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <NewsContext.Provider value={contextValue}>{children}</NewsContext.Provider>;
}
