import axios from 'axios';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';

const getMemberToken = () => {
    return localStorage.getItem('memberToken');
};
const encryptAuth = (data, secretKey) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return ciphertext;
};
const secretKey = '12121sdfsdf345fgh67567ASAD5hjgigertert456456sdfdf';

const api = axios.create({
    baseURL: 'https://backend.institutionofvaluers.net/api/v1/',
    timeout: 50000,
    headers: {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
    },
});

api.interceptors.request.use(
    (config) => {
        const uniqueIdentifier = 'U2FsdGVkX1+RYZUb4zZgdqxNbDBcoS9B/fqpyH93qvbEofh0w1hWybkCrGZLWm3GCFfV9G7HDkpYICxkVi3xUQ==';
        const token = encryptAuth(uniqueIdentifier, secretKey);
        const memberToken = getMemberToken();
        if (memberToken) {
            config.headers.Authorization = `${memberToken}`;
            config.headers['user-type'] = 'member';
        }
        if (token) {
            config.headers['token'] = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('memberIOV');
            localStorage.removeItem('memberToken');
            localStorage.removeItem('memberConfig');
            window.location.href = '/signin';
        }
        return Promise.reject(error);
    }
);

const commonApi = {
    get: (url, params = {}) => {
        return api.get(url, { params });
    },
    post: (url, data = {}) => {
        const headers = {};
        if (data instanceof FormData) {
            headers['Content-Type'] = 'multipart/form-data';
        }
        return api.post(url, data, { headers });
    },
};

export default commonApi;
