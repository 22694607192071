import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import "../../assetss/scss/Header.scss";
import Logo from "../../assetss/img/logo.png";
import { Link, useLocation, useParams } from "react-router-dom";
import { CommonContext } from "../../context";
import { Helmet } from "react-helmet-async";
import UserProfileDropdown from "./UserProfileDropdown ";
// import { Helmet } from "react-helmet-async";

const Header = () => {
  const { indexData, seoData, user, handleLogout } = useContext(CommonContext);
  const { slug } = useParams();
  const location = useLocation();


  const formatText = (text) => {
    if (!text) return '';
    const cleanedText = text.replace(/[^a-zA-Z0-9\s]/g, ' ').replace(/\s+/g, ' ');
    return cleanedText.trim().charAt(0).toUpperCase() + cleanedText.trim().slice(1).toLowerCase();
  };

  const Logo =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "logo")
      ?.option_value;
  const favicon =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "favicon")
      ?.option_value;
  const gaCode =
    indexData &&
    indexData?.settings?.length > 0 &&
    indexData?.settings.find((item) => item.option_key === "ga_code")
      ?.option_value;

  const fallbackSlug = formatText(slug || location.pathname.split('/').pop());
  const siteTitle = seoData?.data?.title || fallbackSlug;
  const siteKeywords = seoData?.data?.keywords || fallbackSlug;
  const siteDescription = seoData?.data?.description || fallbackSlug;


  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState({});
  const handleNavClick = (path) => {
    setActiveLink(path);
  };
  const [activeLink, setActiveLink] = useState("/");
  // New state for play/pause control
  const [isPaused, setIsPaused] = useState(false);

  //  Function to toggle play/pause

  const handlePlayPause = () => {
    setIsPaused((prev) => !prev);
  };
  useEffect(() => {
    if (!gaCode) return;

    // Create a temporary container to parse HTML
    const container = document.createElement("div");
    container.innerHTML = gaCode;

    container.querySelectorAll("script").forEach((script) => {
      if (!script) return; // Ensure script is valid

      const newScript = document.createElement("script");
      if (script.src) {
        newScript.src = script.src;
        newScript.async = true;
      } else if (script.textContent) {
        newScript.textContent = script.textContent;
      }

      if (document.head) {
        document.head.appendChild(newScript);
      } else {
        console.warn("document.head is not available");
      }
    });

  }, [gaCode]);
  useEffect(() => {
    setActiveLink(location.pathname.replace(/%20|%/g, " ").trim());
  }, [location.pathname]);
  const formatSlug = (slug) => {
    const cleanSlug = slug.replace(/[^a-zA-Z0-9- ]/g, " ").trim();
    return cleanSlug
  }
  function createSlug(text) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }
  useEffect(() => {
    if (favicon) {
      const faviconLink = document.querySelector("link[rel='icon']");
      if (faviconLink) {
        faviconLink.href = `${indexData?.path}/${favicon}`;
      }
    }
  }, [favicon]);
  useEffect(() => {
    const setMetaTag = (name, content) => {
      let metaTag = document.querySelector(`meta[name="${name}"]`);
      if (!metaTag) {
        metaTag = document.createElement("meta");
        metaTag.name = name;
        document.head.appendChild(metaTag);
        console.log(`${name} meta tag created`);
      }
      if (metaTag) {
        metaTag.setAttribute("content", content);
        console.log(`${name} meta tag set:`, content);
      } else {
        console.error(`${name} meta tag is null`);
      }
    };

    if (typeof document !== "undefined" && document.head) {
      // Set the page title
      document.title = siteTitle;

      // Set meta tags
      setMetaTag("description", siteDescription);
      setMetaTag("keywords", siteKeywords);
    } else {
      console.warn("document.head is not available");
    }
  }, [siteTitle, siteDescription, siteKeywords]);
  
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);


  const toggleSubMenu = (menu, isOpen) => {
    setIsSubMenuOpen((prev) => ({ ...prev, [menu]: isOpen }));
  };
  return (
    <>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
        <meta name="keywords" content={siteKeywords} />
      </Helmet>
      <div
        className={`overlay ${isMenuOpen ? "active" : ""}`}
        onClick={toggleMenu}
      ></div>

      <div className="top-strip">
        <div className={`top-strip-marquee ${isPaused ? "paused" : ""}`}>
          <div className="marquee-content">
            {indexData?.alerts?.title || "Welcome to our website!"}
          </div>
        </div>
        <Nav color="light" light expand="md" >
          <span className="play-pause-icon" onClick={handlePlayPause}>
            {isPaused ? "►" : "⏸"}
          </span>
          {/* <NavItem>
            <Link className="nav-link" to="/active-professional-valuers">
              APV
            </Link>
          </NavItem> */}
          <NavItem>
            <Link className="nav-link" to="/icv">
              ICV
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="https://institutionofvaluers.net/ekta/">
              Ekta
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/tender">
              Tender
            </Link>
          </NavItem>
          {user ? (
            <>
              {/* <UncontrolledDropdown nav inNavbar className="profile-dropdown">
                <DropdownToggle nav caret className="profile-dropdown-toggle">
                  <div className="profile-circle">
                    {user.name?.charAt(0).toUpperCase()}
                  </div>
                </DropdownToggle>
                <DropdownMenu right className="profile-dropdown-menu">
                  <div className="profile-name">{user.name}</div>
                  <Link to="/profile" className="dropdown-item">My Profile</Link>
                  <DropdownItem divider />
                  <DropdownItem onClick={handleLogout}>Logout</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UserProfileDropdown />
            </>
          ) : (
            <>
              <NavItem>
                <Link className="nav-link" to="/signin">
                  Login
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/signup">
                  Register
                </Link>
              </NavItem>
              {/* <UncontrolledDropdown
                nav
                inNavbar
                onMouseEnter={() => toggleSubMenu("join", true)}
                onMouseLeave={() => toggleSubMenu("join", false)}
                isOpen={isSubMenuOpen["join"]}
                className={`nav-item ${activeLink.startsWith("/list-of-members") ? 'active-dropdown' : ''}`}
              >
                <DropdownToggle nav caret>Register</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem><Link to="/signup" className={`dropdown-item ${activeLink === '/signup' ? 'active' : ''}`} onClick={() => handleNavClick("/signup")}>Online</Link></DropdownItem>
                  <DropdownItem><Link to="/page/offline-membership" className={`dropdown-item ${activeLink === '/page/offline-membership' ? 'active' : ''}`} onClick={() => handleNavClick("/page/offline-membership")}>Offline</Link></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </>
          )}
          {/* <NavItem>
            <Link className="nav-link" to="/signin">
              Login
            </Link>
          </NavItem>
          <NavItem>
            <Link className="nav-link" to="/signup">
              Register
            </Link>
          </NavItem> */}
        </Nav>
      </div>

      <Navbar color="light" light expand="md" className="custom-navbar">
        <NavbarBrand href="/">
          <img src={`${indexData?.settings_path}/${Logo}`} alt="IOV Logo" className="logo" />
        </NavbarBrand>
        <div className="ml-auto d-md-none">
          <FaBars onClick={toggleMenu} className="menu-icon" />
        </div>
        <Nav className="ml-auto d-none d-md-flex" navbar>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/" ? "active" : ""}`} to="/" onClick={() => handleNavClick("/")}>Home</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("about", true)}
            onMouseLeave={() => toggleSubMenu("about", false)}
            isOpen={isSubMenuOpen["about"]}
            className={`nav-item ${["/page/about", "/page/about-founder", "/page/about-secretary", "/page/council", "/blue-book"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>About</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem><Link to="/page/about" className={`dropdown-item ${activeLink === '/page/about' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about")}>Who We Are</Link></DropdownItem>
              <DropdownItem><Link to="/page/about-founder" className={`dropdown-item ${activeLink === '/page/about-founder' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about-founder")}>About Founder</Link></DropdownItem>
              <DropdownItem><Link to="/page/about-secretary" className={`dropdown-item ${activeLink === '/page/about-secretary' ? 'active' : ''}`} onClick={() => handleNavClick("/page/about-secretary")}>Hon. Gen. Secretary Desk</Link></DropdownItem>
              <DropdownItem><Link to="/page/council" className={`dropdown-item ${activeLink === '/page/council' ? 'active' : ''}`} onClick={() => handleNavClick("/page/council")}>Council 2025-2027</Link></DropdownItem>
              <DropdownItem><Link to="/page/committees-list" className={`dropdown-item ${activeLink === '/page/committees-list' ? 'active' : ''}`} onClick={() => handleNavClick("/page/committees-list")}>Committees</Link></DropdownItem>
              <DropdownItem><Link to="/blue-book" className={`dropdown-item ${activeLink === '/blue-book' ? 'active' : ''}`} onClick={() => handleNavClick("/blue-book")}>Blue Book</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("join", true)}
            onMouseLeave={() => toggleSubMenu("join", false)}
            isOpen={isSubMenuOpen["join"]}
            className={`nav-item ${["/list-of-members"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>Join IOV</DropdownToggle>
            <DropdownMenu right>
              <UncontrolledDropdown
                onMouseEnter={() => toggleSubMenu("individualMembership", true)}
                onMouseLeave={() => toggleSubMenu("individualMembership", false)}
                isOpen={isSubMenuOpen["individualMembership"]}
              >
                <DropdownToggle nav caret className="dropdown-item">Individual Membership</DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem><Link to="/signup" className={`dropdown-item ${activeLink === '/signup' ? 'active' : ''}`} onClick={() => handleNavClick("/signup")}>Online</Link></DropdownItem>
                  <DropdownItem><Link to="/page/offline-membership" className={`dropdown-item ${activeLink === '/page/offline-membership' ? 'active' : ''}`} onClick={() => handleNavClick("/page/offline-membership")}>Offline</Link></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <DropdownItem><Link to="/page/institutional-membership" className={`dropdown-item ${activeLink === '/page/institutional-membership' ? 'active' : ''}`} onClick={() => handleNavClick("/page/institutional-membership")}>Institutional Membership</Link></DropdownItem>
              <DropdownItem><Link to="list-of-members" className={`dropdown-item ${activeLink === 'list-of-members' ? 'active' : ''}`} onClick={() => handleNavClick("list-of-members")}>List of Members</Link></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}

          <NavItem>
            <Link to="/list-of-members" className={`nav-link ${activeLink.startsWith("/list-of-members") ? 'active' : ''}`} onClick={() => handleNavClick("/list-of-members")}>List of Members</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/courses" ? "active" : ""}`} to="/courses" onClick={() => handleNavClick("/courses")}>Courses</Link>
          </NavItem>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("grievance", true)}
            onMouseLeave={() => toggleSubMenu("grievance", false)}
            isOpen={isSubMenuOpen["grievance"]}
            className={`nav-item ${["/page/about-committee", "/page/objective", "/grievance"].includes(activeLink) ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>Grievance</DropdownToggle>
            <DropdownMenu right>
              <DropdownItem>
                <Link to="/page/about-committee" className={`dropdown-item ${activeLink === '/page/about-committee' ? 'active' : ''}`}> About Committee </Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/page/objective" className={`dropdown-item ${activeLink === '/page/objective' ? 'active' : ''}`}> Objective</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/grievance" className={`dropdown-item ${activeLink === '/grievance' ? 'active' : ''}`}> File A Grievance</Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown
            nav
            inNavbar
            onMouseEnter={() => toggleSubMenu("updates", true)}
            onMouseLeave={() => toggleSubMenu("updates", false)}
            isOpen={isSubMenuOpen["updates"]}
            className={`nav-item ${activeLink.startsWith("/news") ? 'active-dropdown' : ''}`}
          >
            <DropdownToggle nav caret>Updates</DropdownToggle>
            <DropdownMenu right>
              {indexData?.updates_category && Object.keys(indexData.updates_category).length > 0 && Object.entries(indexData.updates_category).map(([key, value]) => (
                <DropdownItem>
                  <Link to={`news/${createSlug(value)}`} className={`dropdown-item ${activeLink === `/news/${createSlug(value)}` ? 'active' : ''}`}> {value} </Link>
                </DropdownItem>
              ))}
              {/* <DropdownItem>
                <Link to="/page/industry-updates" className={`dropdown-item ${activeLink === '/page/industry-updates' ? 'active' : ''}`}> Industry Updates</Link>
              </DropdownItem>
              <DropdownItem>
                <Link to="/news" className={`dropdown-item ${activeLink === '/news' ? 'active' : ''}`}> News</Link>
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/e-journal" ? "active" : ""}`} to="/e-journal" onClick={() => handleNavClick("/e-journal")}>E-journal</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/events" ? "active" : ""}`} to="/events" onClick={() => handleNavClick("/events")}>Event</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/branches" ? "active" : ""}`} to="/branches" onClick={() => handleNavClick("/branches")}>Branches</Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/contact" ? "active" : ""}`} to="/contact" onClick={() => handleNavClick("/contact")}>Contact</Link>
          </NavItem>
        </Nav>
      </Navbar>
      <div className={`mobile-menu ${isMenuOpen ? "open" : ""}`}>
        <div className="mobile-menu-header">
          <img src="https://backend.institutionofvaluers.net/api/img/settings/logo-907969.png" alt="IOV Logo" className="logo" />
          <FaTimes onClick={toggleMenu} className="close-icon" />
        </div>
        <Nav navbar>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/" ? "active" : ""}`} to="/" onClick={toggleMenu}>
              Home
            </Link>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("about", true)}
              onMouseLeave={() => toggleSubMenu("about", false)}
            >
              About
            </DropdownToggle>
            <DropdownMenu
              right
              className={isSubMenuOpen["about"] ? "show" : ""}
            >
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about" className={`dropdown-item ${activeLink === '/page/about' ? 'active' : ''}`}> Who We Are </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about-founder" className={`dropdown-item ${activeLink === '/page/about-founder' ? 'active' : ''}`}> About Founder </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/about-secretary" className={`dropdown-item ${activeLink === '/page/about-secretary' ? 'active' : ''}`}> Hon. Gen. Secretary Desk </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/council" className={`dropdown-item ${activeLink === '/page/council' ? 'active' : ''}`}> Council 2025-2027 </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/page/committees-list" className={`dropdown-item ${activeLink === '/page/committees-list' ? 'active' : ''}`}> Committees </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link to="/blue-book" className={`dropdown-item ${activeLink === '/blue-book' ? 'active' : ''}`}> Blue Book </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("join", true)}
              onMouseLeave={() => toggleSubMenu("join", false)}
            >
              Join IOV
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["join"] ? "show" : ""}>
              Individual Membership Submenu
              <UncontrolledDropdown>
                <DropdownToggle nav caret className="dropdown-item">
                  Individual Membership
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={toggleMenu}>
                    <Link
                      to="/signup"
                      className={`dropdown-item ${activeLink === '/signup' ? 'active' : ''}`}
                      onClick={() => handleNavClick("/signup")}
                    >
                      Online
                    </Link>
                  </DropdownItem>
                  <DropdownItem onClick={toggleMenu}>
                    <Link
                      to="/page/offline-membership"
                      className={`dropdown-item ${activeLink === '/page/offline-membership' ? 'active' : ''}`}
                      onClick={() => handleNavClick("/page/offline-membership")}
                    >
                      Offline
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              Institutional Membership
              <DropdownItem onClick={toggleMenu}>
                <Link
                  to="/page/institutional-membership"
                  className={`dropdown-item ${activeLink === '/page/institutional-membership' ? 'active' : ''}`}
                  onClick={() => handleNavClick("/page/institutional-membership")}
                >
                  Institutional Membership
                </Link>
              </DropdownItem>
              <DropdownItem onClick={toggleMenu}>
                <Link
                  to="/list-of-members"
                  className={`dropdown-item ${activeLink === '/list-of-members' ? 'active' : ''}`}
                  onClick={() => handleNavClick("/list-of-members")}
                >
                  List of Member
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
          <NavItem>
            <Link
              className="nav-link"
              to="/list-of-members"
              onClick={toggleMenu}
            >
              List of Members
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className="nav-link"
              to="/courses"
              onClick={toggleMenu}
            >
              Courses
            </Link>
          </NavItem>
          {/* <NavItem>
            <Link className="nav-link" to="/grievance" onClick={toggleMenu}>
              Grievance
            </Link>
          </NavItem> */}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("grievance", true)}
              onMouseLeave={() => toggleSubMenu("grievance", false)}
            >
              Grievance
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["grievance"] ? "show" : ""}>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/page/about-committee" className={`dropdown-item ${activeLink === '/page/about-committee' ? 'active' : ''}`}> About Committee </Link>
              </DropdownItem>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/page/objective" className={`dropdown-item ${activeLink === '/page/objective' ? 'active' : ''}`}>
                  Objective
                </Link>
              </DropdownItem>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/grievance" className={`dropdown-item ${activeLink === '/grievance' ? 'active' : ''}`}> File A Grievance </Link>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle
              nav
              caret
              onMouseEnter={() => toggleSubMenu("updates", true)}
              onMouseLeave={() => toggleSubMenu("updates", false)}
            >
              Updates
            </DropdownToggle>
            <DropdownMenu right className={isSubMenuOpen["updates"] ? "show" : ""}>
              {indexData?.updates_category && Object.keys(indexData.updates_category).length > 0 && Object.entries(indexData.updates_category).map(([key, value]) => (
                <DropdownItem
                  onClick={toggleMenu}
                >
                  <Link to={`news/${value}`} className={`dropdown-item ${activeLink === `/news/${value}` ? 'active' : ''}`}> {value} </Link>
                </DropdownItem>
              ))}
              {/* <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/page/industry-updates" className={`dropdown-item ${activeLink === '/page/industry-updates' ? 'active' : ''}`}>
                  Industry Updates
                </Link>
              </DropdownItem>
              <DropdownItem
                onClick={toggleMenu}
              >
                <Link to="/news" className={`dropdown-item ${activeLink === '/news' ? 'active' : ''}`}> News </Link>
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/e-journal" ? "active" : ""}`} to="/e-journal" onClick={toggleMenu}>
              E-Journal
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/events" ? "active" : ""}`} to="/events" onClick={toggleMenu}>
              Event
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/branches" ? "active" : ""}`} to="/branches" onClick={toggleMenu}>
              Branches
            </Link>
          </NavItem>
          <NavItem>
            <Link className={`nav-link ${activeLink === "/contact" ? "active" : ""}`} to="/contact" onClick={toggleMenu}>
              Contact
            </Link>
          </NavItem>
        </Nav>
      </div >
    </>
  );
};

export default Header;
