import React, { act, createContext, useEffect, useState } from 'react';
import commonApi from '../utiles/api';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

export const TenderContext = createContext();

export function TenderContextProvider({ children }) {
    const location = useLocation();

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [tenderData, setTenderData] = useState([]);
    const [listCount, setListCount] = useState('');
    const [tenderDetail, setTenderDetail] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [filterInput, setFilterInput] = useState({
        keyword: '',
        opening_date: '',
        closing_date: '',
        tender_status: activeTab,
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async (currentPage, itemsPerPage) => {
        setTenderData([]);
        setListCount('');
        setIsLoading(true);
        try {
            const response = await commonApi.post(`tender/front/list?page=${currentPage}&per_page=${itemsPerPage}`, filterInput);
            if (response.data.s === 1) {
                setTenderData(response?.data?.data);
                setListCount(response?.data?.totalRecords);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        setIsModalOpen(true);
        try {
            const response = await commonApi.post('tender/front/view', { id: id });
            if (response.data.s === 1) {
                setTenderDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const calculateDaysLeft = (closingDate) => {
        const now = moment();
        const endDate = moment(closingDate);
        return endDate.diff(now, "days");
    };
    useEffect(() => {
        onLoadApi(currentPage, itemsPerPage);
    }, [activeTab, currentPage, itemsPerPage]);
    useEffect(() => {
        if (location?.state) {
            onDetailApi(location.state?.id);
        }
    }, [location]);
    const contextValue = {
        listCount,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        activeTab,
        setActiveTab,
        onLoadApi,
        filterInput,
        setFilterInput,
        calculateDaysLeft,
        toggleModal,
        isModalOpen,
        setIsModalOpen,
        onDetailApi,
        tenderDetail,
        setTenderDetail,
        tenderData,
        setTenderData,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <TenderContext.Provider value={contextValue}>{children}</TenderContext.Provider>;
}
