import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utiles/api';

export const CoursesContext = createContext();

export function CoursesContextProvider({ children }) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const stateParam = query.get('state');
    const rowState = stateParam ? JSON.parse(atob(stateParam)) : null;

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [coursesData, setCoursesData] = useState([]);
    const [courseDetail, setCourseDetail] = useState([]);
    const [courseFaq, setCourseFaq] = useState([]);


    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async (newParam = '0') => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('courses/front/list', { new: newParam });
            if (response.data.s === 1) {
                setCoursesData(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('courses/front/view', { id: id });
            if (response.data.s === 1) {
                setCourseDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onFaqApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('courses/faq/list', { course_id: id });
            if (response.data.s === 1) {
                setCourseFaq(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (location?.pathname === '/courses') {
            onLoadApi();
        }
    }, []);
    useEffect(() => {
        if (rowState && location?.pathname.startsWith("/course-details")) {
            onDetailApi(rowState?.id);
            onFaqApi(rowState?.id);
        }
    }, [location]);

    const contextValue = {
        onLoadApi,
        courseFaq,
        coursesData,
        courseDetail,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <CoursesContext.Provider value={contextValue}>{children}</CoursesContext.Provider>;
}
