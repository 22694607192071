import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utiles/api';

export const EventsContext = createContext();

export function EventsContextProvider({ children }) {
    const location = useLocation();

    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [eventData, setEventData] = useState([]);
    const [eventDetail, setEventDetail] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    const [activeTab, setActiveTab] = useState('upcoming');
    const [filterInput, setFilterInput] = useState({
        keyword: '',
        opening_date: '',
        closing_date: '',
        event_status: 'upcoming',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [offset, setOffset] = useState(0);
    const limit = 6;


    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async (currentPage, itemsPerPage) => {
        setEventData([]);
        setIsLoading(true);
        try {
            const response = await commonApi.post(`events/front/list?page=${currentPage}&per_page=${itemsPerPage}`, filterInput);
            if (response.data.s === 1) {
                setEventData(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('events/front/view', { id: id });
            if (response.data.s === 1) {
                setEventDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onGalleryApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('events/gallery/front/list', {
                event_id: id,
                offset,
                limit,
            });
            setOffset((prevOffset) => prevOffset + limit);
            if (response.data.s === 1) {
                setGalleryData((prevData) => [...prevData, ...response.data.data]);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    // useEffect(() => {
    //     onLoadApi();
    // }, []);

    const contextValue = {
        currentPage,
        setCurrentPage,
        itemsPerPage,
        offset,
        activeTab,
        setActiveTab,
        onLoadApi,
        filterInput,
        setFilterInput,
        onDetailApi,
        onGalleryApi,
        limit,
        galleryData,
        eventData,
        eventDetail,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <EventsContext.Provider value={contextValue}>{children}</EventsContext.Provider>;
}
