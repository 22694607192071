import React, { useEffect, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker.entry';
import pdfFile from '../../assetss/img/IOV_Blue_Book.pdf';
import { Alert, Button, Card, Col } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfFlip = () => {
  const [pages, setPages] = useState([]);
  const [textContents, setTextContents] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [filterPage, setFilterPage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [errors, setErrors] = useState('');
  const [zoom, setZoom] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', checkMobile);
    checkMobile();

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
console.log('location.state', location.state);
  useEffect(() => {
    const loadPDF = async () => {
      try {
        // const loadingTask = pdfjsLib.getDocument({ url: pdfFile });
        const loadingTask = pdfjsLib.getDocument({
          url: `${process.env.REACT_APP_BASE_URL}${location.state}`,
        });
        const pdf = await loadingTask.promise;
        const totalPages = pdf.numPages;
        const pagesArray = [];
        const textArray = [];
        let loadedPages = 0;

        for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;
          const pageImage = canvas.toDataURL();
          pagesArray.push(pageImage);

          const textContent = await page.getTextContent();
          const text = textContent.items.map((item) => item.str).join(' ');
          textArray.push({ pageNum, text });

          loadedPages++;
          setProgress(Math.round((loadedPages / totalPages) * 100));
        }

        setPages(pagesArray);
        setTextContents(textArray);
        setLoading(false);
      } catch (error) {
        console.error('Error loading PDF:', error);
        setErrors('Failed to load PDF. Please try again later.');
      }
    };

    loadPDF();
  }, [location.state]);

  const totalPairs = isMobile ? pages.length : Math.ceil(pages.length / 2);

  const handleNext = () => {
    if (currentPage < totalPairs - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFilterChange = (event) => {
    setFilterPage(event.target.value);
    const pageNumber = parseInt(event.target.value, 10);
    if (pageNumber > 0 && pageNumber <= pages.length) {
      const pair = isMobile ? pageNumber - 1 : Math.floor((pageNumber - 1) / 2);
      setCurrentPage(pair);
    }
  };

  const handleSearch = () => {
    if (!searchQuery.trim()) {
      setErrors('Please enter a search query');
      setSearchResults([]);
      return;
    }

    const results = textContents.map((content) => {
      const regex = new RegExp(`(${searchQuery})`, 'gi');
      if (content.text.toLowerCase().includes(searchQuery.toLowerCase())) {
        const highlightedText = content.text.replace(
          regex,
          '<span style="background-color: yellow;">$1</span>'
        );
        return { ...content, highlightedText };
      }
      return null;
    }).filter(Boolean);

    setSearchResults(results);
    setErrors(results.length ? '' : 'No results found');

    if (results.length > 0) {
      const pageNumber = results[0].pageNum;
      const pair = isMobile ? pageNumber - 1 : Math.floor((pageNumber - 1) / 2);
      setCurrentPage(pair);
    }
  };

  useEffect(() => {
    setSearchResults([]);
    setErrors('');
  }, [searchQuery]);


  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.2, 2)); // Maximum zoom is 2x
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.2, 0.5));
  };

  const handlePageClick = () => {
    setIsZoomed((prevState) => !prevState);
    setZoom((prevState) => (prevState === 1 ? 1.5 : 1));
  };

  const cursorStyle = isZoomed ? 'zoom-out' : 'zoom-in';

  return (
    <>
      <Button color='secondary' onClick={() => navigate(-1)} className='back-btn'>&lt; Back</Button>
      {loading ? (
        <div className="loading-container">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <p>Loading... {progress}%</p>
        </div>
      ) : (
        <Card className="flipbook-card">
          <div className="flipbook-container">
            {isMobile ? (
              <div
                className="flipbook-page"
                style={{ cursor: cursorStyle }}
                onClick={handlePageClick}
              >
                {pages[currentPage] ? (
                  <img
                    src={pages[currentPage]}
                    alt={`Page ${currentPage + 1}`}
                    style={{ width: `${100 * zoom}%` }}
                  />
                ) : (
                  <p>No content available</p>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '2px',
                  cursor: cursorStyle,
                }}
                onClick={handlePageClick}
              >
                {pages[currentPage * 2] && (
                  <img
                    src={pages[currentPage * 2]}
                    alt={`Page ${currentPage * 2 + 1}`}
                    style={{ width: `${50 * zoom}%` }}
                  />
                )}
                {pages[currentPage * 2 + 1] && (
                  <img
                    src={pages[currentPage * 2 + 1]}
                    alt={`Page ${currentPage * 2 + 2}`}
                    style={{ width: `${50 * zoom}%` }}
                  />
                )}
              </div>
            )}
          </div>
          {searchResults.length > 0 && (
            <div className="search-results">
              {searchResults.map((result, index) => (
                <div key={index} onClick={() => setCurrentPage(result.pageNum - 1)}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: result.highlightedText,
                    }}
                  ></p>
                </div>
              ))}
            </div>
          )}
          <div className="row mt-3 justify-content-between">
            <Col md="4" style={{ marginTop: '10px' }}>
              <input
                type="number"
                min="1"
                max={pages.length}
                value={filterPage}
                placeholder="Go to page"
                onChange={handleFilterChange}
                style={{ width: '80px' }}
                className="form-control w-100"
              />
            </Col>
            <Col md="6" style={{ marginTop: '10px' }}>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  value={searchQuery}
                  placeholder="Please enter a search query"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                />
                <Button color="primary" outline onClick={handleSearch} className="m-1">
                  Search
                </Button>
              </div>
              {errors && <Alert color="danger">{errors}</Alert>}
            </Col>
            <Col md="12" style={{ marginTop: '20px' }} className="pagination-blue-book">
              <Button color="primary" outline onClick={handlePrev} disabled={currentPage === 0}>
                Previous
              </Button>
              <Button
                color="primary"
                outline
                onClick={handleNext}
                disabled={currentPage === totalPairs - 1}
                style={{ marginLeft: '10px' }}
              >
                Next
              </Button>
              <Button
                color="secondary"
                outline
                onClick={handleZoomOut}
                style={{ marginLeft: '20px', display: 'none' }}
              >
                Zoom Out
              </Button>
              <Button
                color="secondary"
                outline
                onClick={handleZoomIn}
                style={{ marginLeft: '10px', display: 'none' }}
              >
                Zoom In
              </Button>
            </Col>
          </div>
        </Card>
      )}
    </>
  );
};

export default PdfFlip;
