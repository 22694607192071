import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import commonApi from '../utiles/api';

export const GalleryContext = createContext();

export function GalleryContextProvider({ children }) {

    const location = useLocation();
    const [addModal, setAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [globalSearch, setGlobalSearch] = useState('');
    const [galleryData, setGalleryData] = useState([]);
    const [journalDetail, setJournalDetail] = useState([]);
    const [filterInput, setFilterInput] = useState({
        tags: [],
        years: ['2024'],
        events: [],
    });
    const [offset, setOffset] = useState(0);
    const limit = 6;
    const [hasMore, setHasMore] = useState(true);
    const handleAddModals = () => {
        setAddModal(true);
    };

    const handleCloseModal = () => {
        setAddModal(false);
    };
    const onLoadApi = async () => {
        setIsLoading(true);
        filterInput.offset = offset;
        filterInput.limit = limit;
        try {
            const response = await commonApi.post('events/gallery/front/list', filterInput);
            if (response.data.s === 1) {
                // setGalleryData(response?.data?.data);

                if (response.data.data.length > 0) {
                    setGalleryData((prevData) => [...prevData, ...response.data.data]);
                    setOffset((prevOffset) => prevOffset + limit);
                } else {
                    setHasMore(false);
                }
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDetailApi = async (id) => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('e-journal/front/view', { id: id });
            if (response.data.s === 1) {
                setJournalDetail(response?.data?.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch Data:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    // useEffect(() => {
    //     onLoadApi();
    // }, []);
    useEffect(() => {
        if (location?.state) {
            onDetailApi(location.state?.id);
        }
    }, [location]);
    const contextValue = {
        hasMore,
        setHasMore,
        limit,
        setOffset,
        onLoadApi,
        filterInput,
        setFilterInput,
        galleryData,
        journalDetail,
        isSearch,
        globalSearch,
        isLoading,
        handleAddModals,
        handleCloseModal,
        setAddModal,
        addModal,
        setIsLoading,
        setGlobalSearch,
        setIsSearch
    };

    return <GalleryContext.Provider value={contextValue}>{children}</GalleryContext.Provider>;
}
